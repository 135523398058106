import React from 'react';

const Footer: React.FC = () => {
    return (
        <div className="Footer">
            <h2>Contact</h2>
            <div className="ContactSection">
                <div className="ContactInfo">
                    <p><strong>Email:</strong> <a href="mailto:nicholas.aristizabal@gmail.com">nicholas.aristizabal@gmail.com</a></p>
                </div>
                <div className="SocialLinks" style={{display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '15px'}}>
                    <a href="https://github.com/NickAwrist" target="_blank" rel="noopener noreferrer" className="SocialLink">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                        </svg>
                    </a>
                    <a href="https://linkedin.com/in/nicholas-aristizabal-56ba6b235" target="_blank" rel="noopener noreferrer" className="SocialLink">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                            <rect x="2" y="9" width="4" height="12"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                        </svg>
                    </a>
                </div>
            </div>

            <div className="breakPoint"></div>
            <p style={{textAlign: 'center', fontSize: '0.8rem', opacity: '0.7', marginBottom: '20px'}}>
                © {new Date().getFullYear()} Nicholas Aristizabal
            </p>
        </div>
    );
};

export default Footer;